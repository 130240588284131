import React from 'react'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'

const IndexPage = ({ data }) => {
    const { edges: posts } = data.allMarkdownRemark // data.markdownRemark holds your post data

    return (
        <main>
            <div className="container mx-auto bg-slate-50 h-screen">
                <div className="flex flex-wrap gap-2 justify-around">
                    {posts &&
                        posts.map(({ node: post }) => (
                            <div className="">
                                <div className="w-64 rounded-xl border border-slate-400">
                                    <div className="relative">
                                        <GatsbyImage
                                            image={getImage(
                                                post.frontmatter.featuredimage
                                            )}
                                            alt="test"
                                            className="rounded-tl-xl rounded-tr-xl h-48 w-full"
                                        />
                                        <div className="absolute bottom-0 left-0">
                                            <p className="bg-black text-white">
                                                {post.frontmatter.title}
                                            </p>
                                            <p className="bg-black text-white">
                                                {post.frontmatter.date}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="p-2">{post.excerpt}</div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </main>
    )
}

export const query = graphql`
    {
        allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
            edges {
                node {
                    id
                    fields {
                        slug
                    }
                    excerpt
                    frontmatter {
                        featuredimage {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 256
                                    quality: 25
                                    placeholder: TRACED_SVG
                                )
                            }
                        }
                        title
                        date(fromNow: true)
                    }
                }
            }
        }
    }
`

export default IndexPage
